import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import OwlCarousel from 'react-owl-carousel'
import { useFilterContext } from '../../context/filterContext'
const PromotionalBanner = () => {
    const { promotionalBanner, location_url } = useFilterContext();
    const sliderOptions = {
        loop: true,
        margin: 0,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        items: 1,
        smartSpeed: 1000,
        responsive: {
            0: {
                margin: 0,
                items: 1,
            },
            576: {
                margin: 0,
                items: 1,
            },
            768: {
                margin: 0,
                items: 1,
            },
            992: {
                margin: 0,
                items: 1,
            },
            1200: {
                margin: 0,
                items: 1,
            },
        },
    };
    const [show, setShow] = useState(false)
    useEffect(() => {
        let banner = sessionStorage.getItem('banner');
        if (!banner) {
            sessionStorage.setItem('banner', 'shown');
            setTimeout(() => {
                setShow(true);
            }, 2000)
        }
    }, [])
    return (
        <>
            {promotionalBanner && promotionalBanner.length > 0 &&
                <Modal
                    show={show}
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="promotional-banner-modal"
                >
                    <Modal.Body>
                        <Button className='btn-sm modal-close' onClick={() => setShow(false)}>
                            <i className="fas fa-times"></i>
                        </Button>
                        <OwlCarousel {...sliderOptions}>
                            {
                                promotionalBanner.map((item, i) => (
                                    <Link key={i} to={item.link}>
                                        <img src={'backend'+item.banner_image} alt={item.alt_en} className='img-fluid' />
                                    </Link>
                                ))
                            }
                        </OwlCarousel>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}

export default PromotionalBanner