import React,{useState} from 'react'
import { server } from '../../util/server'
import { useTranslation } from "react-i18next";
import {useFilterContext} from '../../context/filterContext';
import { toast } from 'react-toastify';
export const HomeHeroSec = ({data,categories}) => {
    const { i18n, t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState('');
    const { location_url,location } = useFilterContext();
    const submitData = () => {
        if(selectedValue){
          window.location.replace(location_url+"/"+selectedValue);
        }else{
          toast.error('Please select food type')
        }
    }
  return (
    <section
        className="banner-home img-fluid container-fluid First main-sec" style={{ backgroundImage: `url(${server}/${data.link})`  }}
      >
        <div className="img-fluid col-sm-6 col-md-5 col-md-offset-2 col-lg-6 col-lg-offset-6 col-xs-6  main-img"></div>

        <div className="container-fluid wow fadeIn mid" data-wow-delay="0.1s">
          <div className="container sb">
            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-8 main-txt" dangerouslySetInnerHTML={{ __html: data['text_' + i18n.language] }} />
              {/* <h1 className="site-font color-green f-text mid-text">
                <strong>HUNGRY?</strong>
              </h1>
              <h2 className="site-font color-red main-heading main-text mid-text2">
                <strong>JETZT EAT</strong>
              </h2>
              <h3 className="color-gray site-font heading-t mid-text3">
                <strong>IST FÜR SIE DA</strong>
              </h3> */}

            <div className="col-xl-7 col-lg-7 col-md-12 ">
              <div className="mid-search">
                <form type="GET">
                  <div className="row g-2 search">
                    <div className="col-md-4">
                      <select
                        className="form-select border-0 py-2 search-color site-font m-search"
                        name="location"
                        required
                      >
                        <option value="">{location}</option>
                      </select>
                    </div>

                    <div className="col-md-5">
                      <select
                        className="form-select border-0 py-2 search-color site-font m-search"
                        value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)}
                        required
                      >
                        <option value="">{t("Select Food Type")}</option>
                        {
                          categories.length > 0 && categories.map((item2,index2) => (
                              <option key={index2} value={item2.item_slug}>{item2.item_name}</option>
                          ))
                        }
                        
                      </select>
                    </div>

                    <div className="col-lg-3 col-md-3 ">
                      <button
                        className="btn  btn-danger border-0 w-100 py-2 main-button site-font mid-btn"
                        type="button"
                        onClick={() => submitData()}
                      >
                        {t("Find Food")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}
