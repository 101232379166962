import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
import { server } from '../../util/server';
import {useFilterContext} from '../../context/filterContext';

export const HomeMenuSec = ({data,categories}) => {
    const { i18n } = useTranslation();
    const { location_url} = useFilterContext();
    const options = {
        loop: false,
        margin: 0,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        items: 1,
        smartSpeed: 1500,
        responsive: {
          0: {
            margin: 0,
            items: 1,
          },
          576: {
            margin: 3,
            items: 1,
          },
          768: {
            margin: 3,
            items: 2,
          },
          992: {
            margin: 3,
            items: 3,
          },
          1200: {
            margin: 3,
            items: 4,
          },
        },
      };
  return (
    <section className="m-sec">
    <div className="m-sec2">
      <div className="container">
        <div className="row g-0 gx-5 align-items-end ">
          <div className="col-lg-12">
            <div
              className="col-lg-10 text-center mx-auto mb-5 wow slideInLeft text-white"
              data-wow-delay="0.1s text-center"
            >
              <h2 className="mb-3 site-font color-red about-h h1">
              <strong dangerouslySetInnerHTML={{ __html: data['name_' + i18n.language] }} />
              </h2>
              <div dangerouslySetInnerHTML={{ __html: data['text_' + i18n.language] }} />
            </div>
          </div>
        </div>
        {
          categories.length > 0 &&
            <OwlCarousel
              className="product-popular-carousel wow fadeInUp"
              data-wow-delay="0.1s"
              {...options}
            >
              {
                categories.map((item, index) => (
                    <div key={index} className="">
                      <div className="main-content mb-4">
                        <div className="content-overlay"></div>
                        <img
                          className="content-image"
                          alt={item['alt_'+i18n.language]}
                          src={item.home_page_image ? server+"/"+item.home_page_image.link : server+"/"+item.item_image}
                        />
                        <div className="content-details fadeIn-bottom">
                          <h3 className="content-title">
                            <u>{item.item_name}</u>
                          </h3>
                           
                            {
                              item.subcategory && item.subcategory.length > 0 &&
                              item.subcategory.map((item2, key3) => (
                                <p key={key3} className="content-text"> <i className="fas fa-arrow-right"></i> <Link to={location_url+"/"+item.item_slug+"/"+item2.slug}>{item2.name}</Link></p>
                              ))
                            }
                            
                          
                        </div>
                      </div>
                    </div>
                ))
              }
            </OwlCarousel>
        }
        
      </div>
    </div>
  </section>
  )
}
