import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useFilterContext } from '../context/filterContext';
import { server } from '../util/server';
import axios from 'axios';
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next";
import { Meta } from '../Components/Meta'
import Spinner from '../Components/elements/Spinner';
const FAQ = () => {
    const [spinner, setSpinner] = useState(true);
    const { i18n, t } = useTranslation();
    const [data, setData] = useState([]);
    const { updatemetas, updatePageBanner, page_banner, project } = useFilterContext();
    const getData = async () => {
        try {
            let url = server + "/api/auth/web/faq?page_link=faq&project=" + project + "&lan=" + i18n.language
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setData(data.data)
                updatePageBanner(data.page_banner)
                updatemetas(null, 0, data.meta)
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.success(t(data.message))

            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        getData();

    }, [i18n.language])


    return (
        <>
            <Meta />
            {/* Banner Section Start */}
            {
                page_banner && <section className="banner-n " style={{ backgroundImage: `url(${server}/${page_banner})`, backgroundRepeat: 'no-repeat' }}>
                    <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                        <div className=" g-0 gx-5 align-items-end">
                            <div className="col-lg-12">
                                <div
                                    className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p"
                                    data-wow-delay="0.1s text-center"
                                >
                                    <h1 className="color-red">{t("FAQs")}</h1>
                                    <ul className="bread">
                                        <li><b><Link to="/">{t("Home")}</Link></b></li>
                                        <li className="color-red"><b>{t("FAQs")}</b></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            }
            {/* Banner Section End */}

            {spinner ? <Spinner /> :
                <>
                    {/* Main Section Start */}
                    <section class="py-5">
                        <div className='container py-4'>
                            <div className='row'>
                                <div class="col-md-3 ">
                                    {/* Tabs nav */}
                                    <div class="nav flex-column nav-pills nav-pills-custom st" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        {
                                            data &&
                                            Object.entries(data).map(([index, item], key) => (
                                                <a class={key === 0 ? "nav-link mb-3 p-3 shadow active" : "nav-link mb-3 p-3 shadow"} id={"v_pill_" + key} data-toggle="pill" href={"#v_" + key} role="tab" aria-controls={"v_" + key} aria-selected="true">
                                                    <span class="font-weight-bold small text-uppercase">{index}</span>
                                                </a>
                                            ))
                                        }
                                    </div>
                                    {/* Tabs nav  */}
                                </div>

                                <div class="col-md-9">
                                    {/* Tabs content  */}
                                    <div class="tab-content" id="v-pills-tabContent">
                                        {
                                            data &&
                                            Object.entries(data).map(([index1, item1], key1) => (
                                                <div class={key1 === 0 ? "tab-pane fade shadow rounded bg-white show p-h active" : "tab-pane fade shadow rounded bg-white show p-h"} id={"v_" + key1} role="tabpanel" aria-labelledby={"v_" + key1}>
                                                    <div class="accordion faq-accordian" id={"Accordion_" + key1}>
                                                        <h4 class="font-italic mb-4 color-red">{index1}</h4>
                                                        <div class="card border-0">
                                                            {/* Item 1 */}
                                                            {
                                                                item1.length > 0 && item1.map(row => (
                                                                    <>

                                                                        <div class="card-header" id={"heading_" + row.id}>
                                                                            <h6 class="mb-0 collapsed" data-toggle="collapse" data-target={"#collapse_" + row.id} aria-controls="collapseOne">{row['question_' + i18n.language]}<span class="fa fa-angle-up"></span></h6>
                                                                        </div>
                                                                        <div class="collapse" id={"collapse_" + row.id} aria-labelledby={"heading_" + row.id} data-parent={"#Accordion_" + key1}>
                                                                            <div class="card-body">
                                                                                {row['answer_' + i18n.language]}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))
                                                            }


                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }


                                    </div>
                                    {/* Tabs content  */}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Main Section End */}

                    {/* Support Button Start */}
                    <div className="support-button text-center d-flex align-items-center justify-content-center mt-4 wow fadeInUp mb-5" data-wow-delay="0.5s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp' }}>
                        <h5>
                            <p className="mb-0 px-2">{t("Can't find your answers?")}</p>
                        </h5>
                        <h6><Link to="/contact" className="btn btn-dark color-white">Contact Us</Link></h6>
                    </div>
                    {/* Support Button End */}
                </>
            }

        </>
    )
}

export default FAQ