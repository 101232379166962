
import { createContext, useContext, useReducer } from "react";
import reducer from '../reducer/filterReducer';
import {server} from '../util/server';
import axios from "axios";
import {useEffect} from 'react';
import { toast } from "react-toastify";
const FilterContext = createContext();

const initialState = {
    meta: null,
    page_banner: null,
    project: 'JETZT EAT',
    location: 'Winterthur',
    location_url: 'https://demo.ninjashushi.ch',
    navbar_items: [],
    webSettingsData: null,
    miniReviewSlider:[],
    promotionalBanner:[],
    main_project_url:'https://hamza.wintifood.swiss'
}

export const FilterContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const initailWebsiteSettingData = async() => {
        try {
            let res = await axios.get(server+"/api/auth/web/settings?project=JETZT EAT");
            let data = await res.data;
            return dispatch({type:"WEB_SETTINGS", payload: data})
        } catch (error) {
           toast.error('Unexpected error')
        }
    }
    useEffect(() => {
        initailWebsiteSettingData()
    },[]);

    //update meta
    const updatemetas = (page, page_type = 4, meta = null) => {
        dispatch({ type: 'UPDATE_METAS', payload: meta })

    }

     //page banner
     const updatePageBanner = (data) => {
        dispatch({ type: 'UPDATE_PAGE_BANNER', payload: data })

    }


    return (
        <FilterContext.Provider value={{ ...state, dispatch, updatemetas, updatePageBanner }}>
            {children}
        </FilterContext.Provider>
    )

}

//custom hook
export const useFilterContext = () => {
    return useContext(FilterContext);
}

