import React from 'react'
import { Link } from 'react-router-dom'
import { server } from '../../util/server'
import { useTranslation } from "react-i18next";


export const HomehotLocationsSec = ({ data }) => {
    const { i18n, t } = useTranslation();
    return (
        <section className="loc-sec-clr">
            <div className="loc-sec">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp loc-t" data-wow-delay="0.5s">
                        <div className="mb-3 col-lg-8text-left mx-auto mb-5 wow fadeInUp about-h" data-wow-delay="0.5s text-center ">
                            <h2 className="color-red site-font h1"><strong>{t("Explore Hot Locations")}</strong></h2>
                        </div>
                        <div className="col-lg-4">
                            <div className=" col-lg-4 text-left mx-auto mb-5 wow slideInRight" data-wow-delay="0.1s">

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="grid">
                                {
                                    data.map((item, key) => (
                                        <figure key={key} className="effect-layla">
                                            <img src={server+'/'+item.link} alt={item['alt_'+i18n.language]} />
                                            <figcaption>
                                                <h2>{item.name}</h2>
                                            </figcaption>
                                        </figure>
                                    ))
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
