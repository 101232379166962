import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { server } from "../util/server";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useFilterContext } from '../context/filterContext';
import { HomeAboutSec } from "../Components/elements/HomeAboutSec";
import { HomePageMobileAppSec } from "../Components/elements/HomePageMobileAppSec";
import { HomeCateringSec } from "../Components/elements/HomeCateringSec";
import { HomeSpecialFeaturesSec } from "../Components/elements/HomeSpecialFeaturesSec";
import { HomehotLocationsSec } from "../Components/elements/HomehotLocationsSec";
import { HomeHeroSec } from "../Components/elements/HomeHeroSec";
import { HomeTestimonailsSec } from "../Components/elements/HomeTestimonailsSec";
import { HomeMenuSec } from "../Components/elements/HomeMenuSec";
import { HomePopularProducts } from "../Components/elements/HomePopularProducts";
import { Meta } from "../Components/Meta";
import Spinner from "../Components/elements/Spinner";
const Home = () => {
  const [spinner, setSpinner] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [categories, setCategories] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);
  const { i18n, t } = useTranslation();
  const { updatemetas, project, location, webSettingsData } = useFilterContext();
  const getData = async () => {
    try {
      let url =
        server + "/api/auth/web/home-page?api_type=web&page_link=/&project=" + project + "&location=" + location;
      let res = await axios.get(url);
      let data = await res.data;
      if (data.status === 1) {
        //console.log(data);
        updatemetas(null, 0, data.meta)
        setPageData(data.data);
        setReviews(data.reviews)
        setCategories(data.main_categories)
        setPopularProducts(data.popular_products)
        setSpinner(false)
      } else {
        setSpinner(false)
        toast.error(data.message);
      }
    } catch (error) {
      setSpinner(false)
      return toast.error(t("Unexpected error occurred!"));
    }
  };

  useEffect(() => {
    getData();
  }, []);




  return (
    <>
      <Meta />
      {spinner ? <Spinner /> :
        <>
          {/* Main Section */}
          {
            pageData && pageData['HomePageBanner'] && <HomeHeroSec data={pageData['HomePageBanner'][0]} categories={categories} />
          }
          {/* End Main Section */}

          {/* Second Section */}
          {
            pageData && pageData['homepage_special_features'] && <HomeSpecialFeaturesSec data={pageData['homepage_special_features']} />
          }
          {/* End Second Section */}

          {/* About Start */}
          {
            pageData && pageData['HomePageAbout1'] && <HomeAboutSec data={pageData['HomePageAbout1'][0]} />
          }

          {/* About End */}

          {/* Hot Menu */}

          {
            pageData && pageData['HomePageMenu'] && <HomeMenuSec data={pageData['HomePageMenu'][0]} categories={categories} />
          }
          {/* Hot Menu End*/}

          {/* Start MostPopular items slider */}
          {
            popularProducts && <HomePopularProducts data={popularProducts} />
          }

          {/* End MostPopular items slider */}

          {/* Catering Start */}
          {
            pageData && pageData['homepageCatering'] && <HomeCateringSec data={pageData['homepageCatering'][0]} sliderdata={pageData['catering_details']} />
          }
          {/* Catering End */}

          {/* Hot Locations Start */}

          {
            pageData && pageData['homepage_hot_locations'] && <HomehotLocationsSec data={pageData['homepage_hot_locations']} />
          }
          {/* Hot LOcations End  */}

          {/* App Section Start */}
          {
            pageData && pageData['HomePageMobileApp'] && <HomePageMobileAppSec data={pageData['HomePageMobileApp'][0]} app={pageData['mobile_app_links'][0]} ordersteps={pageData['home_steps_to_order']} />
          }
          {/* App Section End */}

          {/* Testimonials Start */}
          {
            pageData && pageData['Homepage_testimonials'] && <HomeTestimonailsSec data={pageData['Homepage_testimonials'][0]} reviews={reviews} />
          }
          {/* Testimonials End  */}


          {/* Map Section Start  */}
          <section id="contact" className="map-sec">
            {webSettingsData &&
              <div className="map" dangerouslySetInnerHTML={{ __html: webSettingsData.address_number_mail_copyright['MapLink'][0]['text'] }} />


            }

          </section>

          {/* Mao Section End  */}

        </>

      }

    </>
  );
};

export default Home;
