import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {useFilterContext} from '../context/filterContext';
import { useTranslation } from "react-i18next";
import Navbar from './Navbar'
const Header = ({changeLangueg}) => {
  const { i18n, t } = useTranslation();
  const [mobileNav, setMobileNav] = useState(false);
  const { webSettingsData,location_url } = useFilterContext();
  return (
    <>
      {/* topbar */}
      <div className="topnav site-font   ">
        <div className="mobileDisplay h-40"></div>

        {/*  Account Topbar dropdown mobile screen */}
        <div className="dropdown float-end mobileDisplay drop" >
          <button className="  dropdown-toggle drop-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-user me-3"></i>Account
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <Link className="dropdown-item color-red f-15" to={location_url+"/login"}>Login</Link>
            <Link className="dropdown-item color-red f-15" to={location_url+"/register"}>Register</Link>
          </div>
        </div>
        {/* end Account Topbar dropdown */}

        {/* language */}
        <div className="dropdown mobileDisplay drop float-end left-135">
          <button className="  dropdown-toggle drop-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-language me-1 f-20" aria-hidden="true" ></i> {i18n.language}</button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <Link className="dropdown-item color-red f-15" to="#" onClick={() => changeLangueg('en', 'English')}>en</Link>
            <Link className="dropdown-item color-red f-15" to="#" onClick={() => changeLangueg('fr', 'French')}>fr</Link>
            <Link className="dropdown-item color-red f-15" to="#" onClick={() => changeLangueg('it', 'Italian')}>it</Link>
            <Link className="dropdown-item color-red f-15" to="#" onClick={() => changeLangueg('de', 'German')}>de</Link>
          </div>
        </div>
        {/* language */}

        <div className="screenDisplay">
          {
              webSettingsData && webSettingsData.social_icons.length > 0 &&
              webSettingsData.social_icons.map((icons, icon_key) => (
                <Link className="btn btn-social icon-s" key={icon_key} to={icon_key.link} dangerouslySetInnerHTML={{ __html:icons.name }}></Link>
              ))
          }

          <Link className="btn btn-social icon-ot" to={location_url+"/cart"} title="Cart"><i className="fa fa-shopping-cart"></i></Link>
          <Link className="btn btn-social icon-ot" to={location_url+"/search"} title="Search"><i className="fa fa-search"></i></Link>
          <Link className="btn btn-social icon-ot" to={location_url+"/login"} title="Login"><i className="fas fa-sign-in-alt"></i></Link>
          <Link className="btn btn-social icon-ot" to={location_url+"/register"} title="Register"><i className="fa fa-user-plus"></i></Link>

          {/* language */}
          <div className="dropdown drop left-135">
            <button className="  dropdown-toggle drop-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">    {!sessionStorage.getItem('lng_name') ? 'English' : sessionStorage.getItem('lng_name')}</button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link className="dropdown-item color-red f-15" to="#" onClick={() => changeLangueg('en', 'English')}>English</Link>
              <Link className="dropdown-item color-red f-15" to="#" onClick={() => changeLangueg('fr', 'French')}>French</Link>
              <Link className="dropdown-item color-red f-15" to="#" onClick={() => changeLangueg('it', 'Italian')}>Italian</Link>
              <Link className="dropdown-item color-red f-15" to="#" onClick={() => changeLangueg('de', 'German')}>German</Link>
            </div>
          </div>
          {/* language */}

        </div>
      </div>
      {/* topbar End */}

      {/* Navbar */}
      <header className="header-area overlay z-index-5">
        <Navbar />
      </header>
      {/* Navbar End */}

      

    </>
  )
}

export default Header