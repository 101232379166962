import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../util/server";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useFilterContext } from "../../context/filterContext";
import { Meta } from "../../Components/Meta";
import { format } from "date-fns";
import Spinner from "../../Components/elements/Spinner";

const BlogDetail = () => {
  const [spinner, setSpinner] = useState(true);
  const { i18n, t } = useTranslation();
  const { slug } = useParams();
  const [singleBlog, setSingleBlog] = useState(null);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const { updatemetas, updatePageBanner, page_banner, project } = useFilterContext();
  const getSingleBlog = async () => {
    try {
      let url =
        server +
        "/api/auth/web/tourism-detail?slug=" +
        slug +
        "&project=" +
        project;
      let res = await axios.get(url);
      let data = await res.data;
      if (data.status === 1) {
        setSingleBlog(data.data);
        updatePageBanner(data.page_banner);
        updatemetas(null, 0, data.meta);
        setRelatedBlogs(data.related_blogs)
        setSpinner(false)
      } else {
        toast.success(data.message);
        setSpinner(false)
      }
    } catch (error) {
      setSpinner(false)
      return toast.error(t("Unexpected error occurred!"));
    }
  };

  useEffect(() => {
    getSingleBlog();
  }, [slug]);

  return (
    <>
      <Meta />
      {page_banner && (
        <section
          className="banner-n "
          style={{
            backgroundImage: `url(${server}/${page_banner})`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
            <div className=" g-0 gx-5 align-items-end">
              <div className="col-lg-12">
                <div
                  className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p"
                  data-wow-delay="0.1s text-center"
                >
                  <h1 className="color-red">{t("Tourism")}</h1>
                  <ul className="bread">
                    <li>
                      <b>
                        <Link to="/">{t("Home")}</Link>
                      </b>
                    </li>
                    <li>
                      <b>
                        <Link to="/tourism">{t("Tourism")}</Link>
                      </b>
                    </li>
                    <li className="color-red">
                      <b>
                        {singleBlog && singleBlog["title_" + i18n.language]}
                      </b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* Main Banner section Start */}

      {/* Preloader */}
      {spinner ? <Spinner /> :
        <>
          {/* Blog Detail Start */}
          <section id="blog" className="blog">
            <div className="container" data-aos="fade-up">
              <div className="row">

                <div className="col-lg-8 entries">
                  {singleBlog &&
                    <article className="entry entry-single">
                      <div className="entry-img">
                        <img src={server + "/" + singleBlog.main_img} alt="" className="img-fluid" />
                      </div>
                      <h2 className="entry-title">
                        <Link to="#">{singleBlog && singleBlog["title_" + i18n.language]}</Link>
                      </h2>
                      <div className="entry-meta">
                        <ul>
                          <li className="d-flex align-items-center color-red"><i className="far fa-clock me-2"></i> <Link to="#"><time className="color-red">{t("Date")}: {format(new Date(singleBlog && singleBlog["blog_date"]), 'do  MMMM yyyy')}</time></Link></li>
                        </ul>
                      </div>
                      <div className="entry-content">
                        {singleBlog &&
                          singleBlog.blog_detail.length > 0 &&
                          singleBlog.blog_detail.map((item) =>
                            item.content_type === "img" ? (
                              <img
                                src={
                                  item.blog_image &&
                                  server + "/storage/media/" + item.blog_image.image
                                }
                                alt=""
                                className="img-fluid mt-4"
                              />
                            ) : (
                              <div className="mt-4"
                                dangerouslySetInnerHTML={{
                                  __html: item["content_" + i18n.language],
                                }}
                              />
                            )
                          )}
                      </div>
                    </article>
                  }
                </div>

                <div className="col-lg-4">
                  <div className="sidebar">
                    <h3 className="blog-sidebar-title color-red">Related Blogs</h3>
                    <div className="sidebar-item recent-posts">
                      {(relatedBlogs && relatedBlogs.length > 0) ?
                        relatedBlogs.map((item, key) => (
                          <div key={key} className="post-item clearfix">
                            <img src={server + "/" + item.main_img} alt={item['alt_' + i18n.language]} />
                            <h4>
                              <Link to={"/tourism/" + item.slug}>{item['title_' + i18n.language]}</Link>
                            </h4>
                            <time className="color-red"> <i className="far fa-clock me-2"></i>{format(new Date(item.blog_date), 'do  MMMM yyyy')}</time>
                          </div>
                        ))
                        :
                        <p className="color-gray">No related blogs found!</p>
                      }

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>
          {/* Blog Detail End */}
        </>
      }
    </>
  );
};

export default BlogDetail;
