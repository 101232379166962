import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../../util/server";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { BlogList } from "../../Components/elements/BlogList";
import { useFilterContext } from "../../context/filterContext";
import { Meta } from "../../Components/Meta";
import '../../Blog.css'
import Pagination from "react-js-pagination";
import { format } from 'date-fns';
import Spinner from "../../Components/elements/Spinner";

const Blog = () => {
    const [spinner, setSpinner] = useState(true);
    const { i18n, t } = useTranslation();
    const [data, setData] = useState([]);
    const [result, setResult] = useState();
    const [blogSearch, setBlogSearch] = useState('');
    const [filter, setFilter] = useState({ filter: null, search: null });
    const [blogCategories, setBlogCategories] = useState([]);
    const [randomBlogs, setRandomBlogs] = useState([]);
    const { updatemetas, updatePageBanner, page_banner, project } = useFilterContext();
    const getBlogs = async (pageNumber = 1) => {
        try {
            let url =
                server +
                `/api/auth/web/tourism-listing?page=${pageNumber}&page_link=tourism&project=` +
                project;
            if (filter.filter) {
                url += "&category_id=" + filter.filter;
            }
            if (blogSearch !== '') {
                url += "&blogSearch=" + blogSearch + "&lang=" + i18n.language;
            }

            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setResult(data.data)
                setData(data.data.data);
                // setFilterData(data.data)
                setBlogCategories(data.categories);
                updatePageBanner(data.page_banner);
                updatemetas(null, 0, data.meta);
                setRandomBlogs(data.random_blogs)
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.success(t(data.message));
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"));
        }
    };

    useEffect(() => {
        getBlogs();
    }, [filter]);

    const filterByCategory = (category_id) => {
        setFilter({ filter: category_id, search: null });
    };

    const clearFilter = () => {
        setFilter({ filter: null, search: null });
    };


    return (
        <>
            <Meta />
            {/* Main Banner Section Start */}
            {page_banner && (
                <section
                    className="banner-n "
                    style={{
                        backgroundImage: `url(${server}/${page_banner})`,
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                        <div className=" g-0 gx-5 align-items-end">
                            <div className="col-lg-12">
                                <div
                                    className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p"
                                    data-wow-delay="0.1s text-center"
                                >
                                    <h1 className="color-red">Tourism</h1>
                                    <ul className="bread">
                                        <li>
                                            <b>
                                                <Link to="/">Home</Link>
                                            </b>
                                        </li>
                                        <li className="color-red">
                                            <b>Tourism</b>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {/* Main Banner Section Start */}

            {/* Preloader */}
            {spinner ? <Spinner /> :
                <>
                    <section id="blog" className="blog">
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-lg-8 entries">
                                    <div className="row">
                                        {data.length > 0 && <BlogList data={data} />}
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <Pagination
                                            totalItemsCount={result && result.total}
                                            activePage={result && result.current_page}
                                            itemsCountPerPage={result && result.per_page}
                                            onChange={(pageNumber) => { getBlogs(pageNumber) }}
                                            itemClass='page-item'
                                            linkClass='page-link'
                                            activeClass='active'
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="sidebar">
                                        <h3 className="blog-sidebar-title">Search</h3>
                                        <div className="sidebar-item search-form">
                                            <form action="">
                                                <input type="text" onChange={(e) => setBlogSearch(e.target.value)} name="blog_search" className="form-control" />
                                                <button type="button" onClick={() => getBlogs()}><i className="fas fa-search"></i></button>
                                            </form>
                                        </div>

                                        {blogCategories.length > 0 && (
                                            <>
                                                <h3 className="blog-sidebar-title">
                                                    {t("Categories")}
                                                    {filter.filter && (
                                                        <Link
                                                            to="#"
                                                            onClick={() => clearFilter()}
                                                            className="fs-6 float-end color-red"
                                                        >
                                                            Clear
                                                        </Link>
                                                    )}
                                                </h3>
                                                <div className="sidebar-item categories">
                                                    <ul>
                                                        {blogCategories.map((item, key) => (
                                                            <li>
                                                                <Link href="#"
                                                                    className={
                                                                        filter.filter && filter.filter === item.category_id
                                                                            ? "d-block color-red"
                                                                            : "d-block"
                                                                    }
                                                                    key={key}
                                                                    onClick={() => filterByCategory(item.category_id)}
                                                                >
                                                                    {item.blog_category["value_" + i18n.language]}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </>
                                        )}

                                        <h3 className="blog-sidebar-title">Recent Blogs</h3>
                                        <div className="sidebar-item recent-posts">
                                            {randomBlogs.length > 0 ?
                                                randomBlogs.map((item, key) => (
                                                    <div key={key} className="post-item clearfix">
                                                        <img src={server + "/" + item.main_img} alt={item['alt_' + i18n.language]} />
                                                        <h4>
                                                            <Link to={"/tourism/" + item.slug}>{item['title_' + i18n.language]}</Link>
                                                        </h4>
                                                        <time > <i className="far fa-clock me-2"></i>{format(new Date(item.blog_date), 'do  MMMM yyyy')}</time>
                                                    </div>
                                                ))
                                                :
                                                <p className="color-gray">No Blogs Found!</p>
                                            }

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }
        </>
    );
};

export default Blog;
