import React from 'react'
import { Link } from 'react-router-dom'
import '../../Page404.css'
export const Page404 = () => {
  return (
    <section style={{ backgroundColor: "#ffffff" }}>
      <div style={{ paddingTop: "8%", paddingBottom: "8%" }}>
        <div class="number" style={{ textAlign: "center" }}>404</div>
        <div class="text" style={{ textAlign: "center" }}><span>Ooops...</span><br />page not found</div>
        <Link class="me" to="" target="_blank"></Link>
      </div>
    </section>
  )
}
