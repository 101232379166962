import React from 'react'
import OwlCarousel from "react-owl-carousel";
import { server } from '../../util/server'
import { useTranslation } from "react-i18next";

export const HomeCateringSec = ({data,sliderdata}) => {
    const { i18n, t } = useTranslation();
    const cateringOptions = {
        loop: false,
        margin: 0,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        items: 1,
        smartSpeed: 1500,
        responsive: {
          0: {
            margin: 0,
            items: 1,
          },
          576: {
            margin: 3,
            items: 1,
          },
          768: {
            margin: 3,
            items: 1,
          },
          992: {
            margin: 3,
            items: 1,
          },
          1200: {
            margin: 3,
            items: 1,
          },
        },
      };
  return (
    <section className="bg-black color-white">
    <div className="row g-0 align-items-center ct-sec">
      <div className="col-md-6 mt-lg-5 move-up">
        <div className="display-5 animated fadeIn ">
          <h2 className="site-font color-red h1">
          <strong dangerouslySetInnerHTML={{ __html: data['name_' + i18n.language] }} />
          </h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data['text_' + i18n.language] }} />
      </div>

      <div className="col-md-6 animated fadeIn move-down d-none d-md-block">
        <div className="ct-sl">
            {
                sliderdata.length > 0 &&  <OwlCarousel
                className="header-carousel"
                {...cateringOptions}
              >
                {
                    sliderdata.map((item, key) => (
                        <div key={key} >
                        <img className="img-fluid" src={server+"/"+item.name} alt={item['alt_'+i18n.language]} />
                        </div>
                    ))
                }
              </OwlCarousel>
            }
         
        </div>
      </div>
    </div>
  </section>
  )
}
