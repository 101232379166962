import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useFilterContext } from '../context/filterContext';
import { server } from '../util/server';
import axios from 'axios';
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next";
import { Meta } from '../Components/Meta'
import Spinner from '../Components/elements/Spinner';

const DynamicPage = () => {
    const [spinner, setSpinner] = useState(true);
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const [data, setData] = useState([]);
    const [page, setPage] = useState([]);
    const { updatemetas, updatePageBanner, page_banner, project } = useFilterContext();
    const getData = async () => {
        try {
            let url = server + "/api/auth/web/pages" + window.location.pathname + "?project=" + project
            let res = await axios.get(url);
            let data = await res.data;
           // console.log(data);
            if (data.status === 1) {
                setData(data.data)
                updatePageBanner(data.page_banner)
                updatemetas(null, 0, data.meta)
                setPage(data.page)
                setSpinner(false)
            } else {
                setSpinner(false)
                navigate('/404')

            }

        } catch (error) {
            setSpinner(false)
            toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        getData();

    }, [window.location.pathname])
    return (
        <>
            <Meta />
            {/* Banner Section Start */}
            {
                page_banner &&
                <section className="banner-n " style={{ backgroundImage: `url(${server}/${page_banner})`, backgroundRepeat: 'no-repeat' }}>
                    <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                        <div className=" g-0 gx-5 align-items-end">
                            <div className="col-lg-12">
                                <div
                                    className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p"
                                    data-wow-delay="0.1s text-center"
                                >
                                    <h1 className="color-red">{page && page['title_' + i18n.language]}</h1>
                                    <ul className="bread">
                                        <li><b><Link to="/">{t("Home")}</Link></b></li>
                                        <li className="color-red"><b>{page && page['title_' + i18n.language]}</b></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {/* Banner section End */}

            {spinner ? <Spinner /> :
                <>
                    {/* Main Section Start */}
                    <h1 className="page-title mt-5 text-center fw-bold"><b>{page && page['title_' + i18n.language]}</b></h1>
                    <div className="container py-5 left-justify">
                        <div className="row">
                            {
                                data.length > 0 &&
                                data.map(item => (
                                    item.content_type === 'img'
                                        ?
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <img src={server + "storage/media/" + item.page_image.image} alt="" className="img-fluid my-3" />
                                        </div>
                                        :
                                        <div className="col-12 mt-4" dangerouslySetInnerHTML={{ __html: item['content_' + i18n.language] }} />
                                ))
                            }
                        </div>
                    </div>
                    {/* Main Section End */}
                </>
            }

        </>
    )
}

export default DynamicPage