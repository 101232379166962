import React from 'react'
import { server } from '../../util/server'
import { useTranslation } from "react-i18next";

export const HomeAboutSec = ({ data }) => {
    const { i18n} = useTranslation();
    return (
        <section className="a-sec">
            <div className="container m-screen a-sec2">
                <div className="row  align-items-center">
                    <div
                        className="col-lg-6 col-md-6  wow fadeIn "
                        data-wow-delay="0.1s"
                    >
                        <div className=" position-relative overflow-hidden p-5 pe-0 d-none d-md-block a-sec3">
                            <img
                                className="img-fluid w-100"
                                src={server + "/" + data['link']}
                                alt={server + "/" + data['alt_' + i18n.language]}
                            />
                        </div>
                    </div>
                    <div
                        className="col-lg-6 col-md-6 wow fadeIn "
                        data-wow-delay="0.5s"
                    >
                        <h2 className="mb-4 site-font color-red about-h h1">
                            <strong dangerouslySetInnerHTML={{ __html: data['name_' + i18n.language] }} />
                        </h2>
                        <div dangerouslySetInnerHTML={{ __html: data['text_' + i18n.language] }} />
                    </div>
                </div>
            </div>
        </section>
    )
}
