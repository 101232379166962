import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { inquirySchema } from '../Schemas'
import { useTranslation } from 'react-i18next';
import { server } from '../util/server';
import { toast } from 'react-toastify'
import { useFilterContext } from '../context/filterContext';
import axios from 'axios'
import { Meta } from '../Components/Meta'
import Spinner from '../Components/elements/Spinner';

const Contact = () => {
    const [spinner, setSpinner] = useState(true);
    const { i18n, t } = useTranslation();
    const [pageData, setPageData] = useState(null);
    const { location, webSettingsData, project, updatePageBanner, updatemetas, page_banner, location_url } = useFilterContext();

    // let MainImage = pageData ? server+"/"+pageData["section_1"][0]["link"] : server+"/storage/banner/1672208878_contact%20us%20-02.png";
    let MainImage = server + "/storage/banner/1672208878_contact%20us%20-02.png";

    const submitData = async (query, actions) => {
        try {
            query['location'] = location;
            let res = await fetch(server + "/api/auth/web/customer-inquiry", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(query),
            })
            let data = await res.json();
            if (data.status === 1) {
                toast.success(t(data.message));
                actions.resetForm();
            } else {
                toast.error(t(data.message));
            }
        } catch (error) {
            return toast.error(error)
        }


    }

    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: { first_name: '', last_name: '', phone_number: '', email: '', message: '' },
        validationSchema: inquirySchema,
        onSubmit: (values, actions) => {
            submitData(values, actions);
        },
    })

    const getData = async () => {
        try {
            let url =
                server + "/api/auth/web/contact-us?project=" + project + "&page_link=contact";
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                updatemetas(null, 0, data.meta)
                updatePageBanner(data.page_banner)
                setPageData(data.data);
                MainImage = server + "/" + data.data["section_1"][0]["link"]
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message);
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"));
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <Meta />
            {/* Main Banner Section Start */}
            {
                page_banner &&
                <section className="banner-n " style={{ backgroundImage: `url(${server}/${page_banner})`, backgroundRepeat: 'no-repeat' }}>
                    <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                        <div className=" g-0 gx-5 align-items-end">
                            <div className="col-lg-12">
                                <div
                                    className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p"
                                    data-wow-delay="0.1s text-center"
                                >
                                    <h1 className="color-red">{t("Contact")}</h1>
                                    <ul className="bread">
                                        <li>
                                            <b>
                                                <Link to="/">Home</Link>
                                            </b>
                                        </li>
                                        <li className="color-red">
                                            <b>Contact</b>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {/* Main Banner Section End */}

            {spinner ? <Spinner /> :
                <>
                    {/* About Section Start */}
                    <div className="fdiv bg-clr">
                        <br />
                        <div className="container contact-main">
                            <div className="text-center mx-auto mb-5">
                            </div>
                            <div className="row g-4">
                                <div className="col-lg-3 col-md-6 mt-5 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" id="icon-divs">
                                    <div className="team-item contact-main2">
                                        <Link to="#">
                                            <i className="fa fa-home"></i>
                                            <div className="text-center">
                                                <h3 className="fw-bold color-white">Our Location</h3>
                                                <p className="site-font color-white"> {webSettingsData && webSettingsData.address_number_mail_copyright['Address'][0]['name']}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="team-item contact-main2">
                                        <Link to={webSettingsData && 'mailto:' + webSettingsData.address_number_mail_copyright['Email'][0]['name']}>
                                            <i className="fa fa-envelope"></i>
                                            <div className="text-center">
                                                <h3 className="fw-bold color-white">Our Email</h3>
                                                <p className="site-font color-white">{webSettingsData && webSettingsData.address_number_mail_copyright['Email'][0]['name']}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="team-item contact-main2">
                                        <i className="far fa-clock"></i>
                                        <div className="text-center">
                                            <h3 className="fw-bold color-white">Opening Hours</h3>
                                            <p className="site-font color-white">24 Hours</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="team-item contact-main2">
                                        <Link to={webSettingsData && 'tel:' + webSettingsData.address_number_mail_copyright['PhoneNumber'][0]['name']}>
                                            <i className="fa fa fa-phone"></i>
                                            <div className="text-center">
                                                <h3 className="fw-bold color-white">Call Us</h3>
                                                <p className="site-font color-white">{webSettingsData && webSettingsData.address_number_mail_copyright['PhoneNumber'][0]['name']}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br /><br /><br />
                    </div>
                    {/* About Section End */}

                    {/* Contact Form Start*/}
                    <section className="contact-main2">
                        <div className="container padding-10p">
                            <div className="contact-wrap padding-10p px-5">
                                <div className="col-md-12 ">
                                    <div className="form2 h-100">
                                        <h3 className="site-font">Send us a message</h3>
                                        <form onSubmit={handleSubmit} className="mb-1" id="contactForm" name="contactForm">
                                            <div className="row">
                                                <div className="col-md-6 form-group mb-2">
                                                    <label className="col-form-label">First Name *</label>
                                                    <input className="form-control" type="text" placeholder="John" name="first_name"
                                                        value={values.first_name}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.first_name && touched.first_name && (
                                                        <span id='msg'>{errors.first_name}</span>
                                                    )}
                                                </div>
                                                <div className="col-md-6 form-group mb-2">
                                                    <label className="col-form-label">Last Name *</label>
                                                    <input className="form-control" type="text" placeholder="Doe" name="last_name"
                                                        value={values.last_name}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.last_name && touched.last_name && (
                                                        <span id='msg'>{errors.last_name}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 form-group mb-2">
                                                    <label className="col-form-label">Phone</label>
                                                    <input className="form-control" type="text" placeholder="+(41) XX XXX XX XX" name="phone_number" id="contactphone"
                                                        value={values.phone_number}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.phone_number && touched.phone_number && (
                                                        <span id='msg'>{errors.phone_number}</span>
                                                    )}
                                                </div>
                                                <div className="col-md-6 form-group mb-2">
                                                    <label className="col-form-label">Email *</label>
                                                    <input className="form-control" type="email" placeholder="john@gmail.com" name="email"
                                                        value={values.email}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.email && touched.email && (
                                                        <span id='msg'>{errors.email}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 form-group mb-4">
                                                    <label className="col-form-label">Message *</label>
                                                    <textarea className="form-control" name="message" cols="30" rows="4" placeholder="Write your message"
                                                        value={values.message}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    >
                                                    </textarea>
                                                    {errors.message && touched.message && (
                                                        <span id='msg'>{errors.message}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row  ">
                                                <div className="col-lg-8 col-md-8 col-sm-6  col-xs-6 form-group">
                                                    <input type="submit" value="Send Message" className="btn btn-danger py-2 px-4 text-center  follow-1 contact-main2" />
                                                    <span className="submitting"></span>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 social-icons follow">
                                                    <h4 className="font-social ">Follow Us</h4>
                                                    {
                                                        webSettingsData && webSettingsData.social_icons.length > 0 &&
                                                        webSettingsData.social_icons.map((icons, icon_key) => (
                                                            <Link className="color-red px-1" key={icon_key} to={icon_key.link} dangerouslySetInnerHTML={{ __html: icons.name }}></Link>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Contact Form End */}

                    {/* Talk to Human Section Start */}
                    <section className="about-section section-padding contact-background-image" style={{ backgroundImage: `url(${MainImage})`, height: 'auto', paddingTop: '5%', paddingBottom: '5%' }}>
                        <div className="container ">
                            <div className="row">
                                <div className="col-lg- 12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="row wow fadeIn" data-wow-delay="0.1s">
                                        <div className="col-lg- 12 col-md-12 col-sm-12 col-xs-12  contact-main3">
                                            <h2 className="text-center site-font color-red">{pageData && pageData["section_1"][0]["name_" + i18n.language]}</h2>
                                        </div>
                                        <div className="abt-content site-font col-lg- 12 col-md-12">
                                            <p className="center-justify">
                                                {pageData && pageData["section_1"][0]["text_" + i18n.language]}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-center contact-btn fadeIn pt-3" data-wow-delay="0.2s">
                            <button className="btn" type="button"> <Link to={webSettingsData && 'tel:' + webSettingsData.address_number_mail_copyright['PhoneNumber'][0]['name']} className="color-white">Make a Call</Link> </button>
                            <button className="btn ms-1" type="button"> <Link to={location_url + "/user/order-complaint"} className="color-white">Register Complaint</Link> </button>
                        </div>
                    </section>
                    {/* Talk to Human Section End */}

                    {/* Map Section Start */}
                    <section id="contact">
                        {
                            webSettingsData && <div className="map" dangerouslySetInnerHTML={{ __html: webSettingsData.address_number_mail_copyright['MapLink'][0]['text'] }} />
                        }

                    </section>
                    {/* Map Section End */}
                </>
            }

        </>
    )
}

export default Contact