import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { server } from "../util/server";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useFilterContext } from '../context/filterContext';
import { Meta } from '../Components/Meta'
import Spinner from "../Components/elements/Spinner";
const About = () => {
    const [spinner, setSpinner] = useState(true);
    const [pageData, setPageData] = useState([]);
    const { i18n, t } = useTranslation();
    const { updatemetas, updatePageBanner, page_banner, project } = useFilterContext();
    const getData = async () => {
        try {
            let url =
                server + "/api/auth/web/about-us?project=" + project + "&page_link=about-us";
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                updatemetas(null, 0, data.meta)
                updatePageBanner(data.page_banner)
                setPageData(data.data);
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message);
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"));
        }
    };

    useEffect(() => {
        getData();
    }, []);
    return (
        <>
            <Meta />
            {/* Banner Section Start */}
            {page_banner &&
                <section className="banner-n " style={{ backgroundImage: `url(${server}/${page_banner})`, backgroundRepeat: 'no-repeat' }}>
                    <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                        <div className=" g-0 gx-5 align-items-end">
                            <div className="col-lg-12">
                                <div
                                    className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p"
                                    data-wow-delay="0.1s text-center"
                                >
                                    <h1 className="color-red">About Us</h1>
                                    <ul className="bread">
                                        <li>
                                            <b>
                                                <Link to="/">Home</Link>
                                            </b>
                                        </li>
                                        <li className="color-red">
                                            <b>About Us</b>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {/* Banner Section End */}

            {spinner ? <Spinner /> :
                <>
                    {/* Who We Are Start */}
                    <section className="about-main">
                        <div className="container py-3">
                            {pageData && pageData["section_1"] && (
                                <div className="row ">
                                    <div
                                        className="col-lg-6 col-md-12 abou-p wow fadeInUp p-4"
                                        data-wow-delay="0.5s"
                                    >
                                        <h2
                                            className="mb-2 mt-lg-5 color-red h1"
                                            dangerouslySetInnerHTML={{
                                                __html: pageData["section_1"][0]["name_" + i18n.language],
                                            }}
                                        />
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: pageData["section_1"][0]["text_" + i18n.language],
                                            }}
                                        ></div>
                                        {/* <h4 className="quality-title mt-4 site-font features_heading">
                            <i className="fas fa-utensils me-3 about_features_styling"></i>
                            <Link to="#" className="site-font color-white">Swiss Top Restaurants</Link>
                        </h4>
                        <h4 className="quality-title site-font features_heading">
                            <i className="fa fa-align-left me-2" aria-hidden="true"></i>
                            <Link to="#" className="site-font color-white">Unlimited Features</Link>
                        </h4>
                        <h4 className="quality-title site-font features_heading">
                            <i className="fa fa-certificate me-2" aria-hidden="true"></i>
                            <Link to="#" className="site-font color-white">High Quality Products</Link>
                        </h4>
                        <h4 className="quality-title site-font features_heading">
                            <i className="fa fa-truck me-1" aria-hidden="true"></i>
                            <Link to="#" className="site-font color-white">Rapid Delivery</Link>
                        </h4> */}
                                    </div>
                                    <div
                                        className="col-lg-6 col-md-6 wow fadeIn my-column-hide features_image_animation"
                                        data-wow-delay="0.1s"
                                    >
                                        <img
                                            className="img-fluid w-100"
                                            src={server + "/" + pageData["section_1"][0]["link"]}
                                            alt={server + "/" + pageData["section_1"][0]["alt_" + i18n.language]}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                    {/* Who We Are End */}

                    {/* Fastest Delivery Section Start */}
                    <section className="about-main2">
                        <div className="container py-3">
                            {pageData && pageData["section_2"] && (
                                <div className="row ">
                                    <div
                                        className="col-lg-6 col-md-6 move-down wow fadeIn my-column-hide"
                                        data-wow-delay="0.1s"
                                    >
                                        <img
                                            className="img-fluid w-100"
                                            src={server + "/" + pageData['section_2'][0]['link']}
                                            alt={server + "/" + pageData['section_2'][0]['alt_' + i18n.language]}
                                        />
                                    </div>
                                    <div
                                        className="col-lg-6 col-md-12 move-up main2-p p-4 wow fadeInUp"
                                        data-wow-delay="0.5s"
                                    >
                                        <h2 className="mb-2 color-red h1" dangerouslySetInnerHTML={{ __html: pageData['section_2'][0]['name_' + i18n.language] }} />
                                        <div dangerouslySetInnerHTML={{ __html: pageData['section_2'][0]['text_' + i18n.language] }} />
                                    </div>
                                </div>
                            )}

                        </div>
                    </section>
                    {/* Fastest Delivery Section End */}

                    {/* Our Mission Section Start */}
                    <section className="about-main pt-5 pb-lg-0 pb-md-5">
                        <div className="container">
                            {
                                pageData && pageData["section_3"] &&
                                <div className="row">
                                    <div
                                        className="col-lg-6 col-md-12 abou-p wow fadeInUp"
                                        data-wow-delay="0.5s"
                                    >
                                        <h2 className="mb-2 pt-lg-5 color-red h1" dangerouslySetInnerHTML={{ __html: pageData['section_3'][0]['name_' + i18n.language] }} />
                                        <div dangerouslySetInnerHTML={{ __html: pageData['section_3'][0]['text_' + i18n.language] }} />
                                    </div>
                                    <div
                                        className="col-lg-6 col-md-6 wow fadeIn my-column-hide"
                                        data-wow-delay="0.1s"
                                    >
                                        <img
                                            className="img-fluid"
                                            src={server + "/" + pageData['section_3'][0]['link']}
                                            alt={server + "/" + pageData['section_3'][0]['alt_' + i18n.language]}
                                        />
                                    </div>
                                </div>
                            }

                        </div>
                    </section>
                    {/* Our Mission Section End */}

                    {/* Our Vision Section Start */}
                    <section className="about-main2 pt-5 pb-lg-0 pb-md-5">
                        <div className="container">
                            {
                                pageData && pageData["section_4"] && <div className="row ">
                                    <div
                                        className="col-lg-6 col-md-6 move-down wow fadeIn my-column-hide"
                                        data-wow-delay="0.1s"
                                    >
                                        <img
                                            className="img-fluid w-100"
                                            src={server + "/" + pageData['section_4'][0]['link']}
                                            alt={server + "/" + pageData['section_4'][0]['alt_' + i18n.language]}
                                        />
                                    </div>
                                    <div
                                        className="col-lg-6 col-md-12 move-up main2-p wow fadeInUp"
                                        data-wow-delay="0.5s"
                                    >


                                        <h2 className="mb-2 mt-lg-5 pt-lg-5 color-red h1" dangerouslySetInnerHTML={{ __html: pageData['section_4'][0]['name_' + i18n.language] }} />
                                        <div dangerouslySetInnerHTML={{ __html: pageData['section_4'][0]['text_' + i18n.language] }} />
                                    </div>
                                </div>
                            }

                        </div>
                    </section>
                    {/* Our Vision Section End */}
                </>
            }

        </>
    );
};

export default About;
