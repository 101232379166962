import React from 'react'
import { Link } from 'react-router-dom'
import { server } from '../../util/server'
import { useTranslation } from "react-i18next";
import {useFilterContext} from '../../context/filterContext';

export const HomeSpecialFeaturesSec = ({data}) => {
    const { i18n} = useTranslation();
    const { location_url} = useFilterContext();
  return (
    <section className="s-sec">
        <div className="container ">
          <div className="row text-center mid-icons">
            {
                data.length > 0 && data.map((item, key) => (
                    <div key={key} className="col-md-3 col-lg-3 col-sm-6 col-6 mb-3 g-col">
                        <Link to={location_url+"/"+item.text} className="s-img">
                        <img
                            className="rounded-circle"
                            alt={item['alt_'+i18n.language]}
                            src={server+"/"+item.link}
                            data-holder-rendered="true"
                        />
                        <h5 className="my-2  color-white site-font s-font">
                            {item['name_'+i18n.language]}
                        </h5>
                        </Link>
                    </div>
                ))
            }
           
          </div>
        </div>
      </section>
  )
}
