import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { server } from '../../util/server';
import { format } from 'date-fns';
export const BlogList = ({ data }) => {
    const { i18n, t } = useTranslation();
    return (
        <>
            {
                data.map((item, key) => (
                    <div key={key} className="col-md-6 col-12">
                        <article className="entry">
                            <div className="entry-img">
                                <img src={server + "/" + item.main_img} alt={item['alt_' + i18n.language]} className="img-fluid" />
                            </div>
                            <h4 className="entry-title">
                                <Link to={"/tourism/" + item.slug}>{item['title_' + i18n.language]}</Link>
                            </h4>
                            <div className="entry-meta">
                                <ul>
                                    <li className="d-flex align-items-center color-red"><i className="far fa-clock"></i> <Link to={"/tourism/" + item.slug}><time>{format(new Date(item.blog_date), 'do  MMMM yyyy')}</time></Link></li>
                                </ul>
                            </div>
                            <div className="entry-content">
                                <div className="read-more">
                                    <Link to={"/tourism/" + item.slug}>Read More</Link>
                                </div>
                            </div>
                        </article>
                    </div>
                ))
            }

        </>

    )
}
