import React from 'react'
import { ThreeDots  } from 'react-loader-spinner'
const Spinner = () => {
    return (
        <div className='container mb-4 spinner-section'>
            <ThreeDots
                height="70"
                width="70"
                color="#ea1d25"
                wrapperStyle={{ marginTop: '80px', justifyContent: 'center' }}
                wrapperClass=""
                visible={true}
                ariaLabel="three-dots-loading"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
            />
        </div>
      )
}

export default Spinner