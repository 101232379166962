import React from 'react'
import { Link } from 'react-router-dom'
import {useFilterContext} from '../context/filterContext';
import { useTranslation } from "react-i18next";
import { server } from '../util/server';
import { toast } from 'react-toastify';
import axios from 'axios';

const Footer = () => {
    const { webSettingsData,location_url } = useFilterContext();
    const { i18n, t } = useTranslation();
    const newsletter_Subscription = async(email) => {
        try {
            let url = server+"/api/auth/web/newsletter-subscription"
            let res = await axios.post(url, {'subscriber_email' :email });
            let data = await res.data;
            if(data.status === 1){
                toast.success(t(data.message))
                
                document.getElementById('subscriber').value = null;
            }else{
                toast.error(t(data.message))
                if(data.errors){
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => {
                            toast.error(item);
                        })
                        
                    })
                }
            }
           
        } catch (error) {
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    const subscribeToNewsletter = (e) => {
        e.preventDefault();
        const subscriber_mail = document.getElementById('subscriber').value;
        if(!subscriber_mail){
            toast.error(t("Email is required"))
        }else{
            newsletter_Subscription(subscriber_mail);
        }
    }

    return (
        <>
            {/* Footer Start */}
            <div className="container-fluid  text-white-50 footer pt-0 mt-0 wow fadeIn s-sec" data-wow-delay="0.1s">
                <div className="container py-5 s-sec">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <h5 className=" mb-4 color-red">Get In Touch</h5>
                            <p className="mb-2 color-white"><Link to="#" className="color-white"><i className="fa fa-map-marker me-3"></i> {webSettingsData && webSettingsData.address_number_mail_copyright['Address'][0]['name']} </Link></p>

                            <p className="mb-2 color-white"><Link to={webSettingsData && 'tel:'+webSettingsData.address_number_mail_copyright['PhoneNumber'][0]['name']} className="color-white"><i className="fa fa-phone me-3"></i> {webSettingsData && webSettingsData.address_number_mail_copyright['PhoneNumber'][0]['name']} </Link></p>
                            <p className="mb-2 color-white"><Link to={webSettingsData && 'mailto:'+webSettingsData.address_number_mail_copyright['Email'][0]['name']} className="color-white"><i className="fa fa-envelope me-3"></i> {webSettingsData && webSettingsData.address_number_mail_copyright['Email'][0]['name']} </Link></p>

                            <div className="d-flex pt-2">
                                {
                                     webSettingsData && webSettingsData.social_icons.length > 0 &&
                                     webSettingsData.social_icons.map((icons, icon_key) => (
                                       <Link className="btn btn-outline-light btn-social color-red" key={icon_key} to={icon_key.link} dangerouslySetInnerHTML={{ __html:icons.name }}></Link>
                                     ))
                                }

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <h5 className=" mb-4 color-red">Quick Links</h5>
                            {
                                webSettingsData && webSettingsData.quick_links.length > 0 &&
                                webSettingsData.quick_links.map((quickLinks, quickLinkskey) => (
                                        <Link className="btn btn-link color-white" to={quickLinks.status === 0 ? quickLinks.link : location_url+"/"+quickLinks.link} key={quickLinkskey}> {quickLinks['name_'+i18n.language]} </Link>
                                ))
                            } 
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <h5 className=" mb-4 color-red">Our Services</h5>
                            {
                                webSettingsData && webSettingsData.our_services.length > 0 &&
                                webSettingsData.our_services.map((service_item, service_key) => (
                                        <Link className="btn btn-link color-white" to={service_item.status === 0 ? service_item.link : location_url+"/"+service_item.link} key={service_key}> {service_item['name_'+i18n.language]} </Link>
                                ))
                            } 
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6" id="neswletter_div">
                            <h5 className=" mb-4 color-red">Newsletter</h5>
                            <p className="site-font color-white left-justify">You can also subscribe to our newsletter to keep up to date with all new food categories and our services we are going to provide to you. ENJOY YOUR MEAL!</p>
                            <div className="formContainer">
                                <form method="POST" action="">
                                    <fieldset className="fieldInput">
                                        <input className="form-input" type="email" id="subscriber" onChange={(e) => e.target.value} name="subscriber_email" placeholder="Email Address" />
                                        <button type="submit" onClick={(e) => {subscribeToNewsletter(e)}} className="form-submit"><i className="fa fa-paper-plane" aria-hidden="true"></i></button>
                                    </fieldset>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container copyright-container-ghk">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            {webSettingsData && webSettingsData.address_number_mail_copyright['Copyright'][0]['name']}
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                <div className="footer-menu">
                                {
                                    webSettingsData && webSettingsData.footer_bottom_bar.length > 0 &&
                                    webSettingsData.footer_bottom_bar.map((foter_bottom_bar_item, foter_bottom_bar_item_key) => (
                                            <Link key={foter_bottom_bar_item_key} to={foter_bottom_bar_item.status === 0 ? foter_bottom_bar_item.link : location_url+"/"+foter_bottom_bar_item.link}> {foter_bottom_bar_item['name_'+i18n.language]} </Link>
                                    ))
                                } 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer End */}
              {/* Footer for mobile screen */}
              <div className="footer2 mobileDisplay">
                <div className="d-flex">
                    <div className="col-sm-4 col-xs-4 col-xxs-4 f-txt">
                        <Link to="/"> <i className="fa fa-home"></i> Home</Link>
                    </div>

                    {/* <div className="col-sm-4 col-xs-4 col-xxs-4 f-txt blink_me">
                        <Link to="/">Track Order</Link>
                    </div> */}
                    <div className="col-sm-4 col-xs-4 col-xxs-4 f-txt">
                        <Link to={location_url+"/cart"}> <i className="fa fa-cart-plus"></i> Cart </Link>
                    </div>

                    <div className="col-sm-4 col-xs-4 col-xxs-4 f-txt">
                        <Link to="/contact"> <i className="fas fa-phone" style={{ transform: 'scaleX(-1)' }}></i> Contact </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer