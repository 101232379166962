import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useFilterContext } from "../context/filterContext";
import { server } from "../util/server";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Meta } from "../Components/Meta";
import Spinner from "../Components/elements/Spinner";
const Reviews = () => {
  const { type } = useParams()
  const [spinner, setSpinner] = useState(true);
  const { i18n, t } = useTranslation();
  const [data, setData] = useState([]);
  const { updatemetas, updatePageBanner, page_banner, project, location } =
    useFilterContext();
  const getData = async () => {
    try {
      let url;
      if(type==='catering'){
        url = server + "/api/auth/web/reviews/catering?api_type=web&page_link=reviews/catering&project=" + location;
      }
      else{
        url = server + "/api/auth/web/reviews/website?api_type=web&page_link=reviews/order&project=" + location;
      }
      
      let res = await axios.get(url);
      let data = await res.data;
      //console.log(data);
      if (data.status === 1) {
        setData(data.data);
        updatePageBanner(data.page_banner);
        updatemetas(null, 0, data.meta);
        setSpinner(false)
      } else {
        setSpinner(false)
        toast.success(t(data.message));
      }
    } catch (error) {
      setSpinner(false)
      return toast.error(t("Unexpected error occurred!"));
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Meta />
      {/* Banner Section Start */}
      {
        page_banner &&
        <section className="banner-n " style={{ backgroundImage: `url(${server}/${page_banner})`, backgroundRepeat: 'no-repeat' }}>
          <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
            <div className=" g-0 gx-5 align-items-end">
              <div className="col-lg-12">
                <div
                  className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p"
                  data-wow-delay="0.1s text-center"
                >
                  <h1 className="color-red">{t("Reviews")}</h1>
                  <ul className="bread">
                    <li><b><Link to="/">{t("Home")}</Link></b></li>
                    <li className="color-red"><b>{t("Reviews")}</b></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      }

      {spinner ? <Spinner /> :
        <>
          {/* Main Section Start */}
          <section id="testimonials" className="t-pag">
            <div className="testimonial-heading ">
              <h2 className="color-white h1">Reviews</h2>
            </div>
            <div className="testimonial-box-container">
              {data.length > 0 &&
                data.map((item, key) => (
                  <div key={key} className="testimonial-box">
                    <div className="box-top">
                      <div className="profile">
                        <div className="profile-img">
                          <img
                            src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png"
                            alt=""
                          />
                        </div>
                        <div className="name-user">
                          <strong>{item.given_by}</strong>
                        </div>
                      </div>
                      <div className="reviews ">
                        <li className={1 <= item.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                        <li className={2 <= item.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                        <li className={3 <= item.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                        <li className={4 <= item.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                        <li className={5 <= item.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                      </div>
                    </div>
                    <div className="client-comment">
                      <p>{item.review_data}</p>
                    </div>
                  </div>
                ))}
            </div>
          </section>
          {/* Main Section End */}
        </>
      }
    </>
  );
};

export default Reviews;
