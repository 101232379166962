import React from 'react'
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
export const HomeTestimonailsSec = ({ data, reviews }) => {
    const { i18n } = useTranslation();
    const testimonialOptions = {
        loop: false,
        margin: 0,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        items: 1,
        smartSpeed: 1500,
        responsive: {
            0: {
                margin: 0,
                items: 1,
            },
            576: {
                margin: 3,
                items: 1,
            },
            768: {
                margin: 3,
                items: 1,
            },
            992: {
                margin: 3,
                items: 2,
            },
            1200: {
                margin: 3,
                items: 3,
            },
        },
    };
    return (
        <section className="test-sec-bg">
            <div className="container t-top test-sec">
                <div className="text-center mx-auto  wow fadeInUp col-lg-12 test-sec2" data-wow-delay="0.1s">
                    <h2 className="mb-3 color-white site-font about-h h1">
                        <strong dangerouslySetInnerHTML={{ __html: data['name_' + i18n.language] }} />
                    </h2>
                    <h5 className="mb-3 color-white site-font"> </h5>
                </div>
                <div id="demo">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    reviews.length > 0 &&
                                    <OwlCarousel id="testimonial-slider" className="owl-carousel testimonial-carousel mt-4 pb-4" {...testimonialOptions}>
                                        {
                                            reviews.map(item => (
                                                <div className="testimonial" key={item.review_id}>
                                                    <span className="icon fa fa-quote-left color-black"></span>
                                                    <ul className="rating d-block mb-1">
                                                        <li className={1 <= item.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey" }></li>
                                                        <li className={2 <= item.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey" }></li>
                                                        <li className={3 <= item.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey" }></li>
                                                        <li className={4 <= item.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey" }></li>
                                                        <li className={5 <= item.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey" }></li>
                                                    </ul>
                                                    <p className="description color-black text-center">
                                                    {item.review_data}
                                                        <br />
                                                    </p>
                                                    <div className="testimonial-content">
                                                        <div className="pic"><img src="assets/front/images/home/profile.png" alt="" /></div>
                                                        <h3 className="title">{item.given_by}</h3>
                                                        <span className="post">{item.review_date}</span>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </OwlCarousel>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
