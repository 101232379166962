import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
import {useFilterContext} from '../../context/filterContext';

export const HomePopularProducts = ({data}) => {
    const { i18n,t } = useTranslation();
    const { location_url } = useFilterContext();
    const menuOptions = {
        loop: false,
        margin: 0,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        items: 1,
        smartSpeed: 1500,
        responsive: {
          0: {
            margin: 0,
            items: 1,
          },
          576: {
            margin: 3,
            items: 1,
          },
          768: {
            margin: 3,
            items: 2,
          },
          992: {
            margin: 3,
            items: 3,
          },
          1200: {
            margin: 3,
            items: 4,
          },
        },
      };
     
  return (
    <section className="m-popular">
    <div className="container m-popular2">
      <div
        className="text-center mx-auto wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <h2 className="mb-3 color-red site-font h1">
          <strong>{t("Most Popular Items")}</strong>
        </h2>
      </div>
        {
            data.length > 0 &&
                <OwlCarousel
                className="product-popular-carousel wow fadeInUp"
                data-wow-delay="0.1s"
                {...menuOptions}
                >
                    {
                        data.map((item,index) => (
                            <div key={index} className="product-grid bg-red pb-10">
                            <div className="product-image">
                                <Link to={location_url+'/food/' + item.product_id + "-" + item.slug} className="image">
                                <img
                                    className="pic-1"
                                    alt={item['alt_'+i18n.language]}
                                    src={location_url+"/"+item.product_image}
                                />
                                </Link>
                            </div>
                            <div className="product-reviews">
                                <p
                                style={{
                                    display: "inline",
                                    paddingLeft: "20px",
                                    fontSize: "15px",
                                }}
                                ></p>
                                <ul
                                className="rating"
                                style={{
                                    float: "right",
                                    display: "inline",
                                    paddingRight: "20px",
                                }}
                                >
                               <li className={1 <= item.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                                <li className={2 <= item.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                                <li className={3 <= item.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                                <li className={4 <= item.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                                <li className={5 <= item.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                                </ul>
                            </div>
                            <div className="product-content bg-red">
                                <h3 className="title">
                                <Link to={location_url+'/food/' + item.product_id + "-" + item.slug}>{item.product_name}</Link>
                                </h3>
                                {
                            item.discount_type ?
                                <p style={{ fontSize: '13px', color: '#ffffff' }}> <span className='text-decoration-line-through' style={{ color: '#000' }}>CHF {(item.cost_price + item.profit).toFixed(2)}</span> CHF {(item.product_price).toFixed(2)}</p>
                                : <p style={{ fontSize: '13px', color: '#ffffff' }}> CHF {(item.product_price).toFixed(2)}</p>
                        }
                                <Link to={location_url+'/food/' + item.product_id + "-" + item.slug} className="btn btn-dark f-15 border-radius-4">
                                        {t("View Detail")}
                                </Link>
                            </div>
                            </div>
                        ))
                    }
              
                </OwlCarousel>
        }
     
    </div>
  </section>
  )
}
