import './App.css';
import React from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Blog from './Pages/Tourism/Blog';
import { useTranslation } from 'react-i18next';
import BlogDetail from './Pages/Tourism/BlogDetail';
import Reviews from './Pages/Reviews';
// import TermsAndConditions from './Pages/TermsAndConditions';
// import PrivacyPolicy from './Pages/PrivacyPolicy';
import FAQ from './Pages/FAQ';
import DynamicPage from './Pages/DynamicPage';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from "react-toastify";
import { Page404 } from './Pages/error/Page404';
import PromotionalBanner from './Components/elements/PromotionalBanner';
import MiniReviewSlider from './Components/elements/MiniReviewSlider';
import axios from 'axios';
function App() {

  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
  axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*'

  const { i18n } = useTranslation();
  const changeLangueg = (lng,lng_name) => {
      sessionStorage.setItem('lng', lng);
      sessionStorage.setItem('lng_name', lng_name)
      i18n.changeLanguage(lng);
  }

  return (
    <BrowserRouter>
      <ToastContainer />
      <Header changeLangueg={changeLangueg} />
      <PromotionalBanner />
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path='/about-us' element={ <About /> } />
        <Route path='/contact' element={ <Contact /> } />
        <Route path='/tourism' element={ <Blog /> } />
        <Route path='/tourism/:slug' element={ <BlogDetail /> } />
        <Route path='/reviews/:type' element={ <Reviews /> } />
        {/* <Route path='/terms-and-conditions' element={ <TermsAndConditions /> } />
        <Route path='/privacy-policy' element={ <PrivacyPolicy /> } /> */}
        <Route path='/faq' element={ <FAQ /> } />
        <Route path='/404' element={ <Page404 /> } />
        <Route path='/*' element={ <DynamicPage /> } />
      </Routes>
      <MiniReviewSlider />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
