import React from 'react'
import { server } from '../../util/server'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

export const HomePageMobileAppSec = ({ data, app, ordersteps }) => {
    const { i18n, t } = useTranslation();
    return (
        <section className="app-sec-clr app-sec">
            <div className="container ">
                <div className="row  align-items-center">
                    <div className="col-lg-6 col-md-6 wow fadeIn " data-wow-delay="0.5s">
                        <h2 className="mb-4 site-font color-red h1">
                            <strong dangerouslySetInnerHTML={{ __html: data['name_' + i18n.language] }} />
                        </h2>
                        <div style={{ color: '#fff' }} className="mb-3" dangerouslySetInnerHTML={{ __html: data['text_' + i18n.language] }} />
                        <div className="" id="dowload-app1 app-btn-sec">
                            <Link to={app.name}>
                                <img src={server + "/" + app['name_en']} className="app-btn" alt={server + "/" + data['alt_' + i18n.language]} width="150px" /></Link>

                            <Link to={app.name_de}>
                                <img src={server + "/" + app['name_fr']} className="app-btn" alt={server + "/" + data['text_' + i18n.language]} width="150px" /></Link>

                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6  wow fadeIn " data-wow-delay="0.1s">
                        <div className="position-relative overflow-hidden p-5 pe-0 d-none d-md-block ">
                            <img className="img-fluid w-100" src={server + "/" + data['link']}
                                alt={server + "/" + data['alt_' + i18n.language]} />
                        </div>
                    </div>
                </div>
                <div className="row  align-items-center mt-5">
                    <div className="col-12 text-center mx-auto mb-5 wow fadeInUp loc-t" data-wow-delay="0.5s">
                        <div className="text-left mx-auto wow fadeInUp about-h" data-wow-delay="0.5s text-center ">
                            <h2 className="color-red site-font h1"><strong>{t("How to order")}</strong></h2>
                            <p className="text-light mb-0">{t("Follow easy steps below and enjoy your food within 45 - 50 min.")}</p>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mybody">
                            <ol>
                                {
                                    ordersteps.length > 0 && ordersteps.map((item, key) => (
                                        <li key={key} >
                                            <div className="icon"><img src={server + "/" + item['link']}
                                                alt={server + "/" + item['alt_' + i18n.language]} className="d-block m-auto" style={{ height: '40px' }} /></div>
                                            <div className="title"></div>
                                            <div className="descr mt-2" dangerouslySetInnerHTML={{ __html: item['name_' + i18n.language] }} />
                                        </li>
                                    ))


                                }
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
